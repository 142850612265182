<template>
  <div>
    <v-card-text class="bold-f-s text-center pc-title">
      <div>{{transactionMode.question}}</div>
    </v-card-text>
    <div class="pc-container">
      <div class="pc-content">
        <v-card
          v-for="(item, index) in transactionMode.slides"
          :key="index"
          active-class=""
          max-width="480"
          class="m-auto m-t-20 m-b-20"
          @click="chooseTransactionMode(item)"
          :class="active == item.mode ? 'df-b-c' : ''"
          style="border-radius: 15px"
          @mouseover="$event.currentTarget.classList.toggle('select-active')"
          @mouseout="$event.currentTarget.classList.toggle('select-active')"
          v-bind:id="item.title">
          <v-card-text class="bold-f-s p-b-0"><span style="color: #8FBAEC">{{item.title}}</span></v-card-text>
          <v-card-text class="">{{item.note}}</v-card-text>
        </v-card>
        <v-card v-if="showFooter" flat width="50%" class="m-auto">
          <v-card-text style="padding: 30px">
            <i style="font-size: 12px">* Unavoidable delays that occur due to bank holidays, processing schedule of your
              bank, processing errors, “acts of God,” and/or “acts of terror” may extend the time for the deposit</i>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <v-card-actions v-if="showFooter">
      <v-btn
        :disabled="disabledNext"
        large
        color="primary"
        width="240"
        class="m-0 f-s-18"
        @click="next">
        Next
      </v-btn>
    </v-card-actions>
    <!--  confirm  -->
    <v-dialog
      persistent
      max-width="480" v-model="showModal">
      <v-card flat class="b-r-16 p-10">
        <v-card-text class="p-t-20 bold-f-s text-center">Please confirm your choices</v-card-text>
        <v-card-text class="p-b-10">
          <v-row>
            <v-col cols="6">
              <label>Repayment Type:</label>
            </v-col>
            <v-col class="text-right" cols="6">
              <span :style="`color: ${$vuetify.theme.themes.light.primary} !important`"
                    style="font-weight: 600">
                {{transactionMode.repayment}}
              </span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="m-r-20 m-l-20"></v-divider>
        <v-card-text class="p-t-10">
          <v-row>
            <v-col cols="6">
              <label>Funding Type:</label>
            </v-col>
            <v-col class="text-right" cols="6">
            <span :style="`color: ${$vuetify.theme.themes.light.primary} !important`" style="font-weight: 600">
              {{transactionMode.disbursement}}
            </span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="p-b-20">
          <v-btn
            large
            block
            outlined
            class="width-40 m-l-8"
            @click="cancel"
          >
            NO
          </v-btn>
          <v-btn
            large
            block
            color="primary"
            class="width-40"
            @click="confirm"
          >
            YES
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--  1  -->
    <v-dialog
      persistent
      max-width="480" v-model="showModalACH">
      <v-card flat class="b-r-16 p-10">
        <v-btn
          @click="active=0, showModalACH=false" icon style="position: absolute; top: 0px; right: 0px">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text class="f-s-24 bold-f p-t-24 text-center">Optional</v-card-text>
        <v-card-text><span class="bold-f">Adding a Debit Card</span> as an alternative payment method to avoid potential
          ACH fees?
        </v-card-text>
        <v-card-actions class="p-b-20">
          <v-btn
            large
            block
            outlined
            class="width-50"
            @click="ACHNo"
          >
            NO
          </v-btn>
          <v-btn
            large
            block
            color="primary"
            class="width-50 m-r-0"
            @click="ACHYes"
          >
            YES
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--  1.1  -->
    <v-dialog
      persistent
      max-width="480" v-model="showModalInstantCard">
      <v-card
        style="background: transparent;z-index: 2"
        flat
        height="1"
        width="100%"
        class="text-right"
      >
        <v-btn @click="showModalInstantCard=false, active=0" icon>
          <v-icon>close</v-icon>
        </v-btn>
      </v-card>
      <instant-card :portfolio="portfolio" @submit="submit"></instant-card>
    </v-dialog>
    <!--  2  -->
    <v-dialog
      persistent
      max-width="480" v-model="dialogMail">
      <v-card flat class="b-r-16 p-10">
        <v-btn
          icon
          @click="active=0, dialogMail=false"
          style="position: absolute; top: 0px; right: 0px"
        >
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text class="f-s-24 bold-f p-t-24 text-center">Optional</v-card-text>
        <v-card-text>
          If you choose physical repayment, you <span class="bold-f">cannot receive your loan proceeds
          electronically</span>. If approved, your loan
          proceeds will be mailed to you in <span class="bold-f">5 days</span>. Other limitations apply.
        </v-card-text>
        <v-card-actions class="p-b-20">
          <v-btn
            large
            block
            outlined
            class="width-50"
            @click="active=0, dialogMail=false"
          >
            NO
          </v-btn>
          <v-btn
            large
            block
            color="primary"
            class="width-50 m-r-0"
            @click="dialogMailY"
          >
            YES
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--  Follow up  -->
    <v-dialog
      persistent
      max-width="480" v-model="showModalEmail">
      <v-card flat class="b-r-16 p-10">
        <v-card-text class="p-t-20">We need more information based on your choice of repayment, please contact us
          through {{ portfolio.telephone }}
        </v-card-text>
        <follow-up @submit="followUp" @close="followUpClose"></follow-up>
      </v-card>
    </v-dialog>
    <!--  4  -->
    <v-dialog
      persistent
      max-width="480" v-model="showRepaymentMail">
      <v-card flat style="padding: 20px" class="b-r-16">
        <v-card-text class="p-t-20 bold-f-s text-center">Please confirm your choices</v-card-text>
        <v-card-text>
          If approved,your loan proceeds will be mailed to you in <span class="bold-f">5 days</span>. Other limitations
          apply.
        </v-card-text>
        <v-card-actions class="p-b-20">
          <v-btn
            large
            block
            outlined
            class="width-40 m-l-8"
            @click="active=0, showRepaymentMail=false"
          >
            NO
          </v-btn>
          <v-btn
            large
            block
            color="primary"
            class="width-40"
            @click="showRepaymentMail=false, showModalEmail = true"
          >
            YES
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { OriginationApi } from '../../api/application'
import { DataType, Tool } from '../../js/core'
import InstantCard from '../../components/ailurus/InstantCardModal'
import FollowUp from '../../components/ailurus/dialog/FollowUp'

const tool = new Tool()

export default {
  components: { FollowUp, InstantCard },
  data () {
    return {
      page: DataType.PAGES.TRANSACTION,
      active: 0,
      showFooter: false,
      showModalEmail: false,
      portfolio: {},
      showModal: false,
      showModalInstantCard: false,
      showModalACH: false,
      showRepaymentMail: false,
      dialogMail: false,
      disabledNext: true,
      question: {
        repayment: 'Please choose your repayment option:',
        disbursement: 'Please choose your funding method'
      },
      transactionMode: {
        slides: [],
        question: '',
        repayment: '',
        disbursement: ''
      },
      repayment: [
        {
          mode: '1',
          title: 'Electronic',
          note: 'Payment will be charged through ACH or Debit Card.',
          style: ''
        },
        {
          mode: '2',
          title: 'Check by Mail',
          note: 'Payment will be sent through check by mail.',
          style: ''
        }
      ],
      disbursement: [
        {
          mode: '3',
          title: 'Electronic',
          note: 'Funds will be deposited within 24 hours of approval. *',
          style: ''
        },
        {
          mode: '4',
          title: 'Check by Mail',
          note: 'Funds will be sent through check by mail.',
          style: ''
        }
      ],
      progress: 7,
      step: 'bank',
      notes: {
        noteContent: ''
      },
      tags: 0
    }
  },
  methods: {
    async followUp (data) {
      const _this = this
      _this.$store.commit('setOverlay', true)
      this.showModalEmail = false
      if (tool.isNotEmpty(data.followUpAt)) {
        await OriginationApi.updateOrigination({
          feature: 'follow-up',
          data: {
            loanId: data.loanId,
            followUpAt: data.followUpAt,
            notes: this.notes
          }
        })
        if (data.cellPhone) {
          data.cellPhone = data.cellPhone.replace(/[^0-9]/ig, '')
        }
        if (data.homePhone) {
          data.homePhone = data.homePhone.replace(/[^0-9]/ig, '')
        }
        await OriginationApi.updateOrigination({
          feature: 'personal',
          data: {
            loanId: data.loanId,
            cellPhone: data.cellPhone,
            homePhone: data.homePhone
          }
        })
      }
      await OriginationApi.updateOrigination({
        feature: 'loan-status',
        data: {
          loanId: data.loanId,
          loanStatus: DataType.LoanStatus.AGENT_REVIEW.value
        }
      })
      this.$router.push('/instant/notification/-100')
    },
    followUpClose () {
      this.showModalEmail = false
    },
    ACHYes () {
      this.showModalInstantCard = true
      this.showModalACH = false
    },
    ACHNo () {
      this.transactionMode.question = this.question.disbursement
      this.transactionMode.slides = this.disbursement
      this.showModalACH = false
      this.active = 0
      this.showFooter = true
    },
    submit (data) {
      this.showModalInstantCard = false
      this.transactionMode.question = this.question.disbursement
      this.transactionMode.slides = this.disbursement
      this.showFooter = true
    },
    cancel () {
      this.transactionMode.question = this.question.repayment
      this.transactionMode.slides = this.repayment
      this.disabledNext = true
      this.showModal = false
      this.showFooter = false
    },
    confirm () {
      this.disabledNext = false
      this.showModal = false
    },
    samePortfolio () {
      const _this = this
      const portfolioArr = DataType.SAME_PORTFOLIO
      for (const number of portfolioArr) {
        if ((_this.tags & number) === number) {
          return true
        }
      }
      return false
    },
    next () {
      const updatePaymentOption = {}
      updatePaymentOption.loanId = localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID)
      if (this.transactionMode.repayment === 'Check by Mail') {
        updatePaymentOption.defaultRepaymentMode = 0
      } else {
        updatePaymentOption.defaultRepaymentMode = 15000
      }
      if (this.transactionMode.disbursement === 'Check by Mail') {
        updatePaymentOption.defaultDisbursementMode = 0
      } else {
        updatePaymentOption.defaultDisbursementMode = 15000
      }
      OriginationApi.updateOrigination({ feature: 'payment-option', data: updatePaymentOption }, function (result) {
      })
      const stepMessage = {
        currect: DataType.PAGES.TRANSACTION,
        next: DataType.PAGES.PREVIEW
      }
      const _this = this
      const category = localStorage.getItem(DataType.COOKIE_KEY.CATEGORY)
      if (_this.samePortfolio() && parseInt(category) === DataType.CategoryEnum.RETURNING_CUSTOMER.value) {
        OriginationApi.generatePaymentPlanByLoanId({
          loanId: localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
          portfolioId: localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)
        }, function () {
          OriginationApi.enteredAndLeft(
            localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
            DataType.PAGES.TRANSACTION.step,
            DataType.PAGES.SIGNATURE.step,
            localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)
          )
          _this.$router.push(DataType.PAGES.SIGNATURE.addressPC)
        })
      } else {
        this.$eventBus.$emit('sendStepToSloth', stepMessage)
        OriginationApi.enteredAndLeft(
          localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
          DataType.PAGES.TRANSACTION.step,
          DataType.PAGES[_this.page.next].step,
          localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)
        )
        this.$router.push(DataType.PAGES[this.page.next].addressPC)
      }
    },
    dialogMailY () {
      this.showModalEmail = true
      this.notes.noteContent = 'Repayment: Check by Mail'
    },
    chooseTransactionMode (item) {
      const _this = this
      this.active = item.mode
      switch (item.mode) {
        case '1': {
          setTimeout(function () {
            _this.transactionMode.repayment = item.title
            _this.showModalACH = true
          }, 100)
          break
        }
        case '2': {
          setTimeout(function () {
            _this.dialogMail = true
            _this.transactionMode.repayment = item.title
            _this.transactionMode.disbursement = item.title
          }, 100)
          break
        }
        case '3': {
          setTimeout(function () {
            _this.transactionMode.disbursement = item.title
            _this.showModal = true
          }, 100)
          break
        }
        case '4': {
          setTimeout(function () {
            _this.transactionMode.disbursement = item.title
            _this.showRepaymentMail = true
            _this.notes.noteContent = 'Funding: Check by Mail'
          }, 100)
          break
        }
        default : {
          _this.transactionMode.disbursement = item.title
          _this.showModal = true
          break
        }
      }
    }
  },
  created () {
    const _this = this
    _this.$store.commit('setCurrentPage', this.page)
    _this.$store.commit('setOverlay', false)
    this.transactionMode.question = this.question.repayment
    this.transactionMode.slides = this.repayment
    this.portfolio = JSON.parse(localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO))
    OriginationApi.getLoanById({ loanId: localStorage.getItem('LOAN_ID'), feature: 'loan' }, function (result) {
      _this.tags = result.tags
    })
    // 跳转页面
    OriginationApi.pageControl(null, function (pageControl) {
      if (
        pageControl !== null &&
        pageControl !== undefined &&
        pageControl.indexOf(localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)) > 0
      ) {
        _this.page.next = 'MILITARY'
      }
    })
  }
}
</script>
<style lang="css">
  .transaction .v-input--selection-controls__input {
    position: absolute;
    right: -38px;
    z-index: 30;
  }

  .transaction .mdi-checkbox-blank-outline::before {
    content: "\F130";
    font-size: 18px;
  }

  .transaction .mdi-checkbox-marked::before {
    content: "\F133";
    font-size: 18px;
  }
</style>
